/**
 * Function to create css transitions
 * @param {string\|string[]} prop - A single transition property or a list of transition properties
 * @param {number} [duration=150] - Custom transition duration in milliseconds
 * @param {string} [easing='ease-in-out'] - Custom transition easing function
 *
 * @example
 * const single = transition('background-color');
 *
 * console.log(single); // background-color 150ms ease-in-out
 *
 * const multi = transition(['color', 'opacity']);
 *
 * console.log(multi); // color 150ms ease-in-out, opacity 150ms ease-in-out
 *
 * const custom = transition('color', 500, 'cubic-bezier(0.4, 0, 0.6, 1)');
 *
 * console.log(custom); // color 500ms cubic-bezier(0.4, 0, 0.6, 1)
 */
var transition = function transition(prop, duration, easing) {
  if (typeof prop === 'string') {
    return "".concat(prop, " ").concat(duration || 150, "ms ").concat(easing || 'ease-in-out');
  }
  var trans = '';
  prop.forEach(function (p) {
    trans = trans.concat("".concat(p, " ").concat(duration || 150, "ms ").concat(easing || 'ease-in-out', ", "));
  });
  return trans ? trans.slice(0, -2) : trans;
};
export default transition;
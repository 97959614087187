import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';

/**
 * Allows you to control the mounting and unmounting of a component by a given
 * flag.
 *
 * Useful when you need to hide a component but don't want it to be unmounted.
 */
var useMountChildrenValidation = function useMountChildrenValidation(_ref) {
  var isOpen = _ref.isOpen,
    unmountChildrenOnClose = _ref.unmountChildrenOnClose;
  var _useState = useState(isOpen),
    _useState2 = _slicedToArray(_useState, 2),
    wasOpened = _useState2[0],
    setWasOpened = _useState2[1];
  useEffect(function () {
    if (!wasOpened && isOpen) {
      setWasOpened(true);
    }
  }, [wasOpened, isOpen]);
  return !!(unmountChildrenOnClose ? isOpen : isOpen || wasOpened);
};
export default useMountChildrenValidation;
export var pageview = function pageview() {
  var _window;
  // @ts-ignore
  if ((_window = window) !== null && _window !== void 0 && _window.fbq) {
    var _window2;
    // @ts-ignore
    (_window2 = window) === null || _window2 === void 0 ? void 0 : _window2.fbq('track', 'PageView');
  }
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export var event = function event(name) {
  var _window3;
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  // @ts-ignore
  (_window3 = window) === null || _window3 === void 0 ? void 0 : _window3.fbq('track', name, options);
};
/**
 * Languages that crehana supports
 */
export var LANGUAGES_CODES = {
  ES: 'es',
  EN: 'en',
  PT: 'pt'
};

/**
 * Countries that crehana supports.
 */
export var COUNTRIES_CODES = {
  PE: 'pe',
  AR: 'ar',
  MX: 'mx',
  CO: 'co',
  ES: 'es',
  CL: 'cl',
  BO: 'bo',
  CR: 'cr',
  EC: 'ec',
  BR: 'br',
  NOT_SUPPORTED: '*'
};
// @deprecated Please use the LANGUAGE_COOKIE_KEY constant instead
export var LANGUAGE_COOKIE = '__creh_language_code';
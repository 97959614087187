import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { useContext } from 'react';
import { CrehanaI18nContext } from "./Context";
import { validateIsConfig } from "./utils";
/**
 * @param namespace - Optional namespace useful to be certain about which key
 * should be selected (in case there's more than one key with the same name).
 *
 * If you pass a `namespace` through the `t` function, it'll have higher priority.
 */
export function useTranslation(namespace) {
  var context = useContext(CrehanaI18nContext);
  var t = function t(key, defaultValueOrConfig, config) {
    if (typeof defaultValueOrConfig === 'string') {
      var _conf = validateIsConfig(config) ? config : undefined;
      return context.t(key, _objectSpread({
        namespace: namespace,
        defaultValue: defaultValueOrConfig
      }, _conf));
    }
    var conf = validateIsConfig(defaultValueOrConfig) ? defaultValueOrConfig : undefined;
    return context.t(key, _objectSpread({
      namespace: namespace
    }, conf));
  };
  var addResourceBundle = function addResourceBundle(bundle, options) {
    if (process.env.NODE_ENV === 'developmet') {
      if (options) {
        console.warn('addResourceBundle no longer receives an options paramenter');
      }
    }
    return context.addResourceBundle(bundle);
  };
  return {
    t: t,
    addResourceBundle: addResourceBundle,
    languageKey: context.languageCode,
    languageCode: context.languageCode,
    countryCode: context.countryCode,
    countryPrefix: context.countryCode
  };
}
import _objectSpread from "@babel/runtime/helpers/objectSpread2";
var isObject = function isObject(obj) {
  return obj && typeof obj === 'object';
};
export function mergeTranslations(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
target,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
source) {
  if (!isObject(target) || !isObject(source)) {
    return source;
  }
  var _target = _objectSpread({}, target);
  var keys = Object.keys(source);
  for (var i = 0; i < keys.length; i++) {
    var key = keys[i];
    var targetValue = _target[key];
    var sourceValue = source[key];
    if (isObject(targetValue) && isObject(sourceValue)) {
      _target[key] = mergeTranslations(_objectSpread({}, targetValue), sourceValue);
    } else if (typeof sourceValue !== 'undefined') {
      _target[key] = sourceValue;
    }
  }
  return _target;
}
var interpolationRegex = /\{\{(.+?)\}\}/g;
export function getInterpolation(value, replacements) {
  return value.replace(interpolationRegex, function (_, name) {
    var replacement = replacements[name];
    if (replacement === undefined || replacement === null) {
      if (process.env.NODE_ENV === 'development') {
        console.error("Invalid interpolation value \"".concat(name, "\""));
      }
      return '';
    }
    return replacement;
  });
}
export var pipe = function pipe() {
  for (var _len = arguments.length, fns = new Array(_len), _key = 0; _key < _len; _key++) {
    fns[_key] = arguments[_key];
  }
  return function (x) {
    return fns.reduce(function (v, f) {
      return f(v);
    }, x);
  };
};

// Public utils specific to crehana implementation

export function transformInitialStateTranslations(translations) {
  var i18nTranslation = {};
  for (var i = 0; i < translations.length; i++) {
    var translation = translations[i];
    var locales = {};
    for (var j = 0; j < translation.keys.length; j++) {
      var locale = translation.keys[j];
      locales[locale.name] = locale.value;
    }
    if (!i18nTranslation[translation.language]) {
      i18nTranslation[translation.language] = {};
    }
    i18nTranslation[translation.language][translation.page] = locales;
  }
  return i18nTranslation;
}

/** @internal */
export function validateIsConfig(config) {
  return typeof config === 'object';
}

/** @internal */
export function validateIsFormatterConfig(config) {
  return !!config.formatter || !!config.formatters;
}
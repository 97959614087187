import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';
function useIsClient() {
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isClient = _useState2[0],
    setIsClient = _useState2[1];
  useEffect(function () {
    setIsClient(true);
  }, []);
  return isClient;
}
export default useIsClient;
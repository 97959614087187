import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useMutation } from "@apollo/client";
var DELETE_SAVED_COURSE = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "DeleteSavedCourse"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "course_id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "enrollment_id"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "ID"
        }
      },
      directives: []
    }],
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "deleteSavedCourse"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "courseId"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "course_id"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "enrollmentId"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "enrollment_id"
            }
          }
        }],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "success"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "messages"
            },
            arguments: [],
            directives: []
          }]
        }
      }]
    }
  }],
  loc: {
    start: 0,
    end: 171,
    source: {
      body: "mutation DeleteSavedCourse($course_id: ID!, $enrollment_id: ID) {\n  deleteSavedCourse(courseId: $course_id, enrollmentId: $enrollment_id) {\n    success\n    messages\n  }\n}\n",
      name: "GraphQL request",
      locationOffset: {
        line: 1,
        column: 1
      }
    }
  }
};
var useDeleteCourse = function useDeleteCourse() {
  var _useMutation = useMutation(DELETE_SAVED_COURSE, {
      context: {
        clientName: 'v4'
      }
    }),
    _useMutation2 = _slicedToArray(_useMutation, 1),
    deleteCourseMutation = _useMutation2[0];
  var deleteCourse = function deleteCourse(courseId, enrollmentId) {
    return deleteCourseMutation({
      variables: {
        course_id: courseId,
        enrollment_id: enrollmentId
      }
    }).then(function (response) {
      var _response$data, _response$data$delete;
      return !!((_response$data = response.data) !== null && _response$data !== void 0 && (_response$data$delete = _response$data.deleteSavedCourse) !== null && _response$data$delete !== void 0 && _response$data$delete.success);
    });
  };
  return {
    deleteCourse: deleteCourse
  };
};
export default useDeleteCourse;
var logger = {
  success: function success(text) {
    var color = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '#26a65b';
    return void 0;
  },
  error: function error(text) {
    var color = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '#cf000f';
    return void 0;
  },
  warn: function warn(text) {
    var color = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '#d35400';
    return void 0;
  }
};
export default logger;
/* eslint-disable no-restricted-properties */
import { isPast } from "../date/isPast";

/**
 * Here we have our custom localStorage method to prevent errors from localStorage on computers with no disk space left.
 */
var customLocalStorage = {
  setItem: function setItem(keyName, keyValue) {
    try {
      return window.localStorage.setItem(keyName, keyValue);
    } catch (error) {
      return null;
    }
  },
  getItem: function getItem(keyName) {
    try {
      return window.localStorage.getItem(keyName);
    } catch (error) {
      return null;
    }
  },
  removeItem: function removeItem(keyName) {
    try {
      return window.localStorage.removeItem(keyName);
    } catch (error) {
      return undefined;
    }
  },
  clear: function clear() {
    try {
      return window.localStorage.clear();
    } catch (error) {
      return null;
    }
  },
  clearExpiredKeys: function clearExpiredKeys() {
    var expirableLocalStorageKeys = ['CREHANA__USER_JUST_LOGGED'];
    try {
      expirableLocalStorageKeys.forEach(function (localStorageKey) {
        var storedDate = window.localStorage.getItem(localStorageKey);
        if (storedDate && isPast(new Date(storedDate))) {
          window.localStorage.removeItem(localStorageKey);
        }
      });
    } catch (error) {
      console.error('Clear local storage expired keys');
    }
  }
};
export default customLocalStorage;
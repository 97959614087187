/**
 * Gets the current user device by parsing the `userAgent`.
 * This API is not compatible with SSR.
 */
function getDeviceType() {
  var ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'Tablet';
  }
  if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return 'Smartphone';
  }
  return 'Desktop';
}
export default getDeviceType;
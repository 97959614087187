import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useMutation } from "@apollo/client";
var CREATE_SAVED_COURSE = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "CreateSavedCourse"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "course_id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      },
      directives: []
    }],
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "createSavedCourse"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "courseId"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "course_id"
            }
          }
        }],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "success"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "messages"
            },
            arguments: [],
            directives: []
          }]
        }
      }]
    }
  }],
  loc: {
    start: 0,
    end: 121,
    source: {
      body: "mutation CreateSavedCourse($course_id: ID!) {\n  createSavedCourse(courseId: $course_id) {\n    success\n    messages\n  }\n}\n",
      name: "GraphQL request",
      locationOffset: {
        line: 1,
        column: 1
      }
    }
  }
};
var useSavedCourse = function useSavedCourse() {
  var _useMutation = useMutation(CREATE_SAVED_COURSE, {
      context: {
        clientName: 'v4'
      }
    }),
    _useMutation2 = _slicedToArray(_useMutation, 1),
    saveCourseMutation = _useMutation2[0];
  var saveCourse = function saveCourse(courseId) {
    return saveCourseMutation({
      variables: {
        course_id: courseId
      }
    }).then(function (response) {
      var _response$data, _response$data$create;
      return !!((_response$data = response.data) !== null && _response$data !== void 0 && (_response$data$create = _response$data.createSavedCourse) !== null && _response$data$create !== void 0 && _response$data$create.success);
    });
  };
  return {
    saveCourse: saveCourse
  };
};
export default useSavedCourse;
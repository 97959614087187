import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from 'react';
var useDisclosure = function useDisclosure() {
  var defaultOpenStatus = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var _useState = useState(defaultOpenStatus),
    _useState2 = _slicedToArray(_useState, 2),
    isOpen = _useState2[0],
    setIsOpen = _useState2[1];
  var onClose = function onClose() {
    setIsOpen(false);
  };
  var onOpen = function onOpen() {
    setIsOpen(true);
  };
  var onToggle = function onToggle() {
    setIsOpen(!isOpen);
  };
  return {
    isOpen: isOpen,
    onClose: onClose,
    onOpen: onOpen,
    onToggle: onToggle
  };
};
export default useDisclosure;
var parseTotalCols = function parseTotalCols(totalCols) {
  var total = {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12
  };
  if (typeof totalCols === 'number') {
    total = {
      xs: totalCols,
      sm: totalCols,
      md: totalCols,
      lg: totalCols
    };
  } else {
    var xs = (totalCols === null || totalCols === void 0 ? void 0 : totalCols.xs) || 12;
    var sm = (totalCols === null || totalCols === void 0 ? void 0 : totalCols.sm) || xs;
    var md = (totalCols === null || totalCols === void 0 ? void 0 : totalCols.md) || sm;
    var lg = (totalCols === null || totalCols === void 0 ? void 0 : totalCols.lg) || md;
    total = {
      xs: xs,
      sm: sm,
      md: md,
      lg: lg
    };
  }
  return total;
};
var getWidthStyles = function getWidthStyles(total, span) {
  if (span === 'auto') {
    return 'flex: 1';
  }
  return "\n    width: ".concat(span >= total ? 100 : span / total * 100, "%;\n    max-width: ").concat(span >= total ? 100 : span / total * 100, "%;\n  ");
};
var getResponsiveWidthStyles = function getResponsiveWidthStyles(totalCols, cols) {
  var xsProp = cols.xs,
    smProp = cols.sm,
    mdProp = cols.md,
    lgProp = cols.lg;
  var xs = xsProp || totalCols.xs;
  var sm = smProp || xs;
  var md = mdProp || sm;
  var lg = lgProp || md;
  return {
    xsWidth: getWidthStyles(totalCols.xs, xs),
    smWidth: sm !== xs || totalCols.sm !== totalCols.xs ? getWidthStyles(totalCols.sm, sm) : '',
    mdWidth: md !== sm || totalCols.md !== totalCols.sm ? getWidthStyles(totalCols.md, md) : '',
    lgWidth: lg !== md || totalCols.lg !== totalCols.md ? getWidthStyles(totalCols.lg, lg) : ''
  };
};
var getOffsetStyles = function getOffsetStyles(total, offset) {
  if (offset === 'auto') {
    return 'margin-left: auto;';
  }
  return "margin-left: ".concat(offset >= total ? 100 : offset / total * 100, "%;");
};
var parseOffsets = function parseOffsets(offset) {
  if (!offset) {
    return {
      xs: 0,
      sm: 0,
      md: 0,
      lg: 0
    };
  }
  if (typeof offset === 'number' || typeof offset === 'string') {
    return {
      xs: offset,
      sm: offset,
      md: offset,
      lg: offset
    };
  }
  var xs = offset.xs || 0;
  var sm = typeof offset.sm === 'undefined' ? xs : offset.sm;
  var md = typeof offset.md === 'undefined' ? sm : offset.md;
  var lg = typeof offset.lg === 'undefined' ? md : offset.lg;
  return {
    xs: xs,
    sm: sm,
    md: md,
    lg: lg
  };
};
var getResponsiveOffsetStyles = function getResponsiveOffsetStyles(totalCols, offset) {
  var _parseOffsets = parseOffsets(offset),
    xs = _parseOffsets.xs,
    sm = _parseOffsets.sm,
    md = _parseOffsets.md,
    lg = _parseOffsets.lg;
  return {
    xsOff: xs ? getOffsetStyles(totalCols.xs, xs) : '',
    smOff: sm !== xs || totalCols.sm !== totalCols.xs ? getOffsetStyles(totalCols.sm, sm) : '',
    mdOff: md !== sm || totalCols.md !== totalCols.sm ? getOffsetStyles(totalCols.md, md) : '',
    lgOff: lg !== md || totalCols.lg !== totalCols.md ? getOffsetStyles(totalCols.lg, lg) : ''
  };
};
var getResponsiveStyled = function getResponsiveStyled(_ref) {
  var theme = _ref.theme,
    xs = _ref.xs,
    sm = _ref.sm,
    md = _ref.md,
    lg = _ref.lg,
    offset = _ref.offset,
    totalCols = _ref.totalCols;
  var total = parseTotalCols(totalCols);
  var _getResponsiveWidthSt = getResponsiveWidthStyles(total, {
      xs: xs,
      sm: sm,
      md: md,
      lg: lg
    }),
    xsWidth = _getResponsiveWidthSt.xsWidth,
    smWidth = _getResponsiveWidthSt.smWidth,
    mdWidth = _getResponsiveWidthSt.mdWidth,
    lgWidth = _getResponsiveWidthSt.lgWidth;
  var _getResponsiveOffsetS = getResponsiveOffsetStyles(total, offset),
    xsOff = _getResponsiveOffsetS.xsOff,
    smOff = _getResponsiveOffsetS.smOff,
    mdOff = _getResponsiveOffsetS.mdOff,
    lgOff = _getResponsiveOffsetS.lgOff;
  var styles = xsWidth || xsOff ? "\n     ".concat(xsWidth, "\n     ").concat(xsOff, "\n  ") : '';
  if (smWidth || smOff) {
    styles = styles.concat("\n       ".concat(theme.breakpoints.up('sm'), " {\n         ").concat(smWidth, "\n         ").concat(smOff, "\n       }\n    "));
  }
  if (mdWidth || mdOff) {
    styles = styles.concat("\n       ".concat(theme.breakpoints.up('md'), " {\n         ").concat(mdWidth, "\n         ").concat(mdOff, "\n       }\n    "));
  }
  if (lgWidth || lgOff) {
    styles = styles.concat("\n       ".concat(theme.breakpoints.up('lg'), " {\n         ").concat(lgWidth, "\n         ").concat(lgOff, "\n       }\n    "));
  }
  return styles;
};
export default getResponsiveStyled;
import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { ApolloLink } from '@apollo/client';
import { md5 } from '@crehana/utils';
var operationNameLink = function operationNameLink(pageKey) {
  return new ApolloLink(function (operation, forward) {
    if (operation.operationName) {
      var operationName = operation.operationName,
        query = operation.query,
        variables = operation.variables;
      var h = {
        'graphql-operation-type': 'query',
        'graphql-operation-name': operationName
      };
      var graphqlOperationDefinition = query.definitions.find(function (d) {
        return d.kind === 'OperationDefinition';
      });
      if (graphqlOperationDefinition) {
        h['graphql-operation-type'] = graphqlOperationDefinition.operation;
      }
      if (pageKey) {
        h['graphql-operation-source'] = pageKey;
      }
      if (process.env.CREHANA_MICROFRONTEND_NAME) {
        h['graphql-application-name'] = process.env.CREHANA_MICROFRONTEND_NAME;
      }
      try {
        h['graphql-operation-id'] = md5(unescape(decodeURIComponent(JSON.stringify({
          operationName: operationName,
          variables: variables
        }))));
      } catch (e) {
        console.error('Falied to stringify operationid', e);
      }
      operation.setContext(function (_ref) {
        var _ref$headers = _ref.headers,
          headers = _ref$headers === void 0 ? {} : _ref$headers;
        return {
          headers: _objectSpread(_objectSpread({}, headers), h)
        };
      });
    }
    return forward(operation);
  });
};
export default operationNameLink;
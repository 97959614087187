import getResponsiveStyled from "./getResponsiveStyled";
var getResponsiveStyledDefault = function getResponsiveStyledDefault(_ref) {
  var theme = _ref.theme,
    xs = _ref.xs,
    sm = _ref.sm,
    md = _ref.md,
    lg = _ref.lg,
    offset = _ref.offset,
    _ref$totalCols = _ref.totalCols,
    totalCols = _ref$totalCols === void 0 ? 12 : _ref$totalCols;
  return getResponsiveStyled({
    theme: theme,
    xs: xs,
    sm: sm,
    md: md,
    lg: lg,
    offset: offset,
    totalCols: totalCols
  });
};
export default getResponsiveStyledDefault;
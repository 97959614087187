import AlertCircle from '@crehana/icons/outline/AlertCircle';
import AlertTriangle from '@crehana/icons/outline/AlertTriangle';
import CheckmarkCircle from '@crehana/icons/outline/CheckmarkCircle';
import Info from '@crehana/icons/outline/Info';
import { greenMain, purple, redMain, yellowMain } from "./colors";
export var stateTextColorClassNames = {
  success: 'text-green-main',
  warning: 'text-yellow-main',
  alert: 'text-red-main',
  info: 'text-purple-main'
};
export var stateIcons = {
  success: CheckmarkCircle,
  warning: AlertCircle,
  alert: AlertTriangle,
  info: Info
};
export var stateIconColors = {
  success: greenMain,
  warning: yellowMain,
  alert: redMain,
  info: purple
};
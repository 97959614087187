import { MembershipTypeMethod, UserDegreeSegmentationEnum } from "../../__generated__/graphql-schema";
import { B2B_ORGANIZATION_ASSIGNED_TYPES } from "../constants";
export var getStudentType = function getStudentType(_ref) {
  var _membership$membershi;
  var loading = _ref.loading,
    error = _ref.error,
    user = _ref.user;
  var _ref2 = user || {},
    segmentation = _ref2.segmentation,
    isStaff = _ref2.isStaff,
    isMentor = _ref2.isMentor,
    isProfessor = _ref2.isProfessor,
    isAffiliate = _ref2.isAffiliate,
    hasMembership = _ref2.hasMembership,
    membership = _ref2.membership,
    hasOrganization = _ref2.hasOrganization,
    organization = _ref2.organization,
    userDegreeSegmentation = _ref2.userDegreeSegmentation;
  var isAnonymous = !loading && !error && !user;
  var isActiveDegree = userDegreeSegmentation === UserDegreeSegmentationEnum.ACTIVATED_DEGREE_SEGMENTATION;
  var isExpiredDegree = userDegreeSegmentation === UserDegreeSegmentationEnum.EXPIRED_DEGREE_SEGMENTATION;
  var isPremium = hasMembership || segmentation === 'Member';
  var isPremiumTrial = (membership === null || membership === void 0 ? void 0 : (_membership$membershi = membership.membershipType) === null || _membership$membershi === void 0 ? void 0 : _membership$membershi.method) === MembershipTypeMethod.A_2;
  var isB2B = hasOrganization || segmentation === 'B2B';
  var isB2BAllCatalog = (organization === null || organization === void 0 ? void 0 : organization.assignedType) === B2B_ORGANIZATION_ASSIGNED_TYPES.ALL_CATALOG;
  var isB2BOnlyPanel = (organization === null || organization === void 0 ? void 0 : organization.assignedType) === B2B_ORGANIZATION_ASSIGNED_TYPES.ONLY_PANEL;
  var organizationRole = organization === null || organization === void 0 ? void 0 : organization.myRole;
  var isB2BAdmin = isB2B && (organizationRole === 'admin' || organizationRole === 'owner' || organizationRole === 'owner-corporation');
  var isNewUser = segmentation === 'Register';
  var isPAYG = !isAnonymous && !isPremium && !isPremiumTrial && !isActiveDegree && !isB2B;
  var studentType;
  if (user) {
    if (isActiveDegree) {
      studentType = 'degree';
    } else if (isB2B) {
      studentType = 'b2b';
    } else if (isPremium) {
      if (isPremiumTrial) {
        studentType = 'premium-trial';
      } else {
        studentType = 'premium';
      }
    } else if (isNewUser) {
      studentType = 'register';
    } else {
      studentType = 'payg';
    }
  } else if (isAnonymous) {
    studentType = 'anonymous';
  }
  return {
    loading: !!loading,
    error: !!error,
    studentType: studentType,
    isAnonymous: isAnonymous,
    isActiveDegree: isActiveDegree,
    isExpiredDegree: isExpiredDegree,
    isB2B: isB2B,
    isB2BAllCatalog: isB2BAllCatalog,
    isB2BOnlyPanel: isB2BOnlyPanel,
    isB2BAdmin: isB2BAdmin,
    isPremium: isPremium,
    isPremiumTrial: isPremiumTrial,
    isPAYG: isPAYG,
    isNewUser: isNewUser,
    isStaff: !!isStaff,
    isMentor: !!isMentor,
    isTeacher: !!isProfessor,
    isAffiliate: !!isAffiliate
  };
};
export default getStudentType;
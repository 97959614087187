export var screens = {
  sm: '768px',
  md: '1024px',
  lg: '1366px'
};
export var screensWidth = {
  sm: 768,
  md: 1024,
  lg: 1366
};
/*
 * You can provide as a param:
 * 1. A breakpoint key
 * 2. An object specifying from, until or only what breakpoints it should match
 * 3. A custom width (should be avoided if possible)
 */
function matchMediaQuery(options) {
  var mediaQuery = '';
  if (typeof options === 'string') {
    mediaQuery = "(min-width: ".concat(screens[options] || options, ")");
  } else {
    var from = options.from,
      until = options.until,
      only = options.only;
    var minWidth = from && from !== 'xs' ? screensWidth[from] : undefined;
    var maxWidth = until ? screensWidth[until] - 1 : undefined;

    // If 'only' is provided, the min and max width is between that breakpoint and the next one (if any)
    if (only) {
      minWidth = only !== 'xs' ? screensWidth[only] : undefined;
      switch (only) {
        case 'xs':
          maxWidth = screensWidth.sm - 1;
          break;
        case 'sm':
          maxWidth = screensWidth.md - 1;
          break;
        case 'md':
          maxWidth = screensWidth.lg - 1;
          break;
        default:
          maxWidth = undefined;
      }
    }
    if (minWidth && maxWidth) {
      mediaQuery = "(min-width: ".concat(minWidth, "px) and (max-width: ").concat(maxWidth, "px)");
    } else if (minWidth) {
      mediaQuery = "(min-width: ".concat(minWidth, "px)");
    } else if (maxWidth) {
      mediaQuery = "(max-width: ".concat(maxWidth, "px)");
    }
  }
  var nativeMediaQueryList = window.matchMedia(mediaQuery);
  return nativeMediaQueryList;
}
export default matchMediaQuery;
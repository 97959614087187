import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';
import { customLocalStorage } from '@crehana/utils';
function useDebugGrid(defaultValue, ignoreKeyboard) {
  var _useState = useState(!!defaultValue),
    _useState2 = _slicedToArray(_useState, 2),
    debugGrid = _useState2[0],
    setDebugGrid = _useState2[1];
  useEffect(function () {
    setDebugGrid(!!defaultValue);
  }, [defaultValue]);
  useEffect(function () {
    var shouldAddListener = typeof document !== 'undefined' && !ignoreKeyboard && (process.env.NODE_ENV !== 'production' || process.env.ENABLE_CREHUI_DEBUG || customLocalStorage.getItem('crehana_ui_debugger_enabled'));
    var listener = function listener(event) {
      if (event.ctrlKey && event.shiftKey && (event.code === 'Digit4' || event.code === 'Numpad4')) {
        setDebugGrid(function (prevDebugGrid) {
          return !prevDebugGrid;
        });
      }
    };
    if (shouldAddListener) {
      document.addEventListener('keydown', listener);
    }
    return function () {
      if (shouldAddListener) {
        document.removeEventListener('keydown', listener);
      }
    };
  }, [ignoreKeyboard]);
  return debugGrid;
}
export default useDebugGrid;
import { useMemo } from 'react';
import { initializeApolloClient } from "./initializeApolloClient";
export function useApolloClient(initialState, countryCode, pageKey) {
  var store = useMemo(function () {
    return initializeApolloClient({
      initialState: initialState,
      countryCode: countryCode,
      pageKey: pageKey
    });
  }, [countryCode, initialState, pageKey]);
  return store;
}
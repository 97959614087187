var createRecursiveFunction = function createRecursiveFunction(callback, options) {
  var _fn = function fn() {
    if (options.condition()) {
      callback();
    } else {
      setTimeout(_fn, options.optTimeout || 1000);
    }
  };
  return _fn;
};
export default createRecursiveFunction;
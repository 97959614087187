export var screens = {
  sm: '768px',
  md: '1024px',
  lg: '1366px'
};
export var breakpoints = {
  up: function up(key) {
    if (Object.hasOwnProperty.call(screens, key)) {
      return "@media (min-width: ".concat(screens[key], ")");
    }
    return '';
  },
  down: function down(key) {
    if (Object.hasOwnProperty.call(screens, key)) {
      return "@media (max-width: ".concat(Number(screens[key].replace('px', '')) - 1, "px)");
    }
    return '';
  }
};
export var isBreakpointKeyType = function isBreakpointKeyType(key) {
  return Object.keys(screens).includes(key);
};
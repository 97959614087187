import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectSpread from "@babel/runtime/helpers/objectSpread2";
export function parseCookies(cookieString) {
  if (cookieString) {
    var cookiesArray = cookieString.split(';');
    var cookiesObj = cookiesArray.reduce(function (acc, cookie) {
      var parts = cookie.split('=');
      var part = parts.shift();
      if (part) {
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, part.trim(), decodeURI(parts.join('='))));
      }
      return acc;
    }, {});
    return cookiesObj;
  }
  return {};
}
export default parseCookies;
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';
var useWindowInnerHeight = function useWindowInnerHeight() {
  var _useState = useState(0),
    _useState2 = _slicedToArray(_useState, 2),
    windowInnerHeight = _useState2[0],
    setWindowInnerHeight = _useState2[1];
  useEffect(function () {
    var _window = window,
      innerHeight = _window.innerHeight;
    setWindowInnerHeight(innerHeight);
    var updateWindoInnerHeight = function updateWindoInnerHeight() {
      setWindowInnerHeight(window.innerHeight);
    };
    window.addEventListener('resize', updateWindoInnerHeight);
    return function () {
      window.removeEventListener('resize', updateWindoInnerHeight);
    };
  }, []);
  return windowInnerHeight;
};
export default useWindowInnerHeight;
import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { ApolloClient, ApolloLink } from '@apollo/client/core';
import { getG11nDataFromHeaders, parseCookies } from "../utils";
import { apolloCacheConfig } from "./cache-config";
import { appReleaseLink, cloudflareAccessLink, countryPrefixLink, createIsomorphLink, crehanaCurrentUrlLink, customHeadersLink, distinctIdLink, errorLink, operationNameLink, userAgentLink } from "./links";
var apolloClient;
function createApolloClient(countryCode, pageKey) {
  var links = [errorLink, distinctIdLink, crehanaCurrentUrlLink, appReleaseLink, userAgentLink, cloudflareAccessLink, customHeadersLink, operationNameLink(pageKey), countryPrefixLink(countryCode), createIsomorphLink()];
  if (typeof window === 'undefined') {
    var logLink = new ApolloLink(function (operation, forward) {
      return forward(operation).map(function (result) {
        return result;
      });
    });
    links = [logLink].concat(_toConsumableArray(links));
  }
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from(links),
    credentials: 'include',
    cache: apolloCacheConfig
  });
}
export function initializeApolloClient() {
  var _apolloClient2;
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    initialState: undefined,
    context: undefined,
    countryCode: '',
    headers: undefined
  };
  var countryCode = options.countryCode;
  var initialState = options.initialState,
    pageKey = options.pageKey,
    headers = options.headers;
  if (!countryCode) {
    if (headers) {
      var _getG11nDataFromHeade = getG11nDataFromHeaders(headers),
        _countryCode = _getG11nDataFromHeade.countryCode;
      countryCode = _countryCode;
    } else if (typeof window !== 'undefined') {
      // If we don't get the CountryPrefix from the server try to get it on the client
      var country = parseCookies(document.cookie).__creh_country_code;
      if (country && country !== '*') {
        countryCode = country.toLowerCase();
      }
    }
  }
  var _apolloClient = (_apolloClient2 = apolloClient) !== null && _apolloClient2 !== void 0 ? _apolloClient2 : createApolloClient(countryCode, pageKey);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    var existingCache = _apolloClient.extract();
    _apolloClient.cache.restore(_objectSpread(_objectSpread({}, existingCache), initialState));
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}
/**
 * It takes a url and a source and returns a new url with the source query params appended to it
 * This util is also exported in the @crehana/analytics library (packages/analytics/src/Amplitude/utils.ts)
 *
 * @param  - url: The url you want to add the query params to.
 * @returns A string
 */
var createUrlWithSourceQueryParams = function createUrlWithSourceQueryParams(_ref) {
  var url = _ref.url,
    source = _ref.source,
    sourceDetail = _ref.sourceDetail;
  /* Checking if the url is empty and if it is, it will return an empty string. */
  if (!url) {
    console.error('createUrlWithSourceQueryParams: You are providing an empty url!');
    return '';
  }
  /* Splitting the url into two parts. The first part is the url root and the second part is the query
  params. */
  var splittedUrl = url.split(/\?(.+)/);
  /* Taking the first part of the url and assigning it to the variable urlRoot. */
  var urlRoot = splittedUrl[0];
  /* Taking the second part of the url and assigning it to the variable urlQueryParams. */
  var urlQueryParams = splittedUrl[1];
  return "".concat(urlRoot, "?").concat(urlQueryParams ? "".concat(urlQueryParams, "&") : '', "source_page=").concat(source).concat(sourceDetail ? "&source_detail=".concat(sourceDetail) : '');
};
export default createUrlWithSourceQueryParams;
/**
 * values[0] : Cookie name and value concatenated`
 * values[1] : Cookie name
 * values[2] : Cookie value
 */
import customDecodeURIComponent from "./customDecodeURIComponent";

/**
 * Get a cookie by name
 * Keep in mind that this will only work in client-side so be sure to don't run this function in server side.
 * You can use @crehana/react-hooks useGetCookie if you wan't to support SSR.
 */
export var getCookie = function getCookie(name) {
  var result = {};
  document.cookie.split(';').forEach(function (cookie) {
    var values = cookie.trim().match(/(\w+)=(.*)/);
    if (values && name === values[1]) {
      result[values[1]] = values[2];
    }
  });
  return result;
};

/**
 * values[0] : Cookie name and value concatenated
 * values[1] : Cookie name
 * values[2] : Cookie value
 */
export var getAll = function getAll(_rawCookies) {
  var rawCookies = _rawCookies || document.cookie;
  var cookies = {};
  try {
    rawCookies.split(';').forEach(function (cookie) {
      var values = cookie.trim().match(/(\w+)=(.*)/);
      if (values) {
        cookies[values[1].trim()] = customDecodeURIComponent(values[2]);
      }
    });
  } catch (error) {
    console.error('getAll: Error to parse cookies', error);
  }
  return cookies;
};

/**
 * expDate : Must be an UTC date.
 */
export var setCookie = function setCookie(name, value) {
  var expDays = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  var expDate = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
  if (!name || !value) {
    console.warn('You must pass the first two parameters (name and value) of the cookie');
    return;
  }
  var expDateWithDays = new Date();
  var offSet = expDays * 24 * 60 * 60 * 1000;
  expDateWithDays.setTime(expDateWithDays.getTime() + offSet);
  document.cookie = "".concat(name, "=").concat(value, ";expires=").concat(expDate || expDateWithDays.toUTCString(), ";path=/");
};
export var deleteCookie = function deleteCookie(name) {
  document.cookie = "".concat(name, "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;");
};
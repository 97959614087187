import smothscroll from 'smoothscroll-polyfill';

import { App, MyAppProps } from '@crehana/next';

import 'nprogress/nprogress.css';
import '@crehana/next/dist/main.css';
import '@crehana/tailwindcss/dist/crehana-tailwindcss.css';
import '@crehana/ui/dist/crehana-ui.min.css';

if (typeof window !== 'undefined') {
  smothscroll.polyfill();
  // @ts-ignore
  window.__forceSmoothScrollPolyfill__ = true;
}

const CustomApp: React.FC<React.PropsWithChildren<MyAppProps>> = props => {
  return (
    <App
      {...props}
      pageProps={{
        ...props.pageProps,
        disabledShoppingCart: true,
      }}
    />
  );
};

export default CustomApp;

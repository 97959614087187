import { parseQueryStrings } from '@crehana/utils';
import { UpperFunnelJobToBeDoneEnum, UpperFunnelSourceEnum } from "./types";
export var getUserTrackingIntent = function getUserTrackingIntent() {
  var qs = window.location.search;
  var _ref = parseQueryStrings(qs) || {},
    jobToBeDone = _ref.upperFunnelJobToBeDone,
    source = _ref.upperFunnelSource;

  // Checking if the query params for job to be done are correct
  if (typeof jobToBeDone === 'string' && Object.values(UpperFunnelJobToBeDoneEnum).includes(jobToBeDone) && typeof source === 'string' && Object.values(UpperFunnelSourceEnum).includes(source)) {
    return {
      jobToBeDone: jobToBeDone,
      source: source,
      urlSource: window.location.href
    };
  }
  return undefined;
};
import { createUrlWithSourceQueryParams as _createUrlWithSourceQueryParams, customLocalStorage, parseQueryStrings } from '@crehana/utils';
/**
 * One of the requirements for using Amplitude is using natural language keys
 * with the first letter capitalized.
 */
export function isValidEventType(eventType) {
  if (!eventType) {
    if (process.env.NODE_ENV === 'development') {
      console.error('Amplitude eventType must be a non empty string');
    }
    return false;
  }
  if (typeof eventType !== 'string') {
    if (process.env.NODE_ENV === 'development') {
      console.error("Amplitude eventType must be a string, received: ".concat(JSON.stringify(eventType)));
    }
    return false;
  }

  // Validates that every word is capitalized
  if (eventType.match(/(\b[a-z]{3,}|\w[A-Z]{3,})|_|\./g)) {
    if (process.env.NODE_ENV === 'development') {
      console.error("Amplitude eventType must be a valid string, received: ".concat(eventType, ".\n        remember to capitalize every word, ex: \"Render Page\""));
    }
    return false;
  }
  return true;
}

/**
 * The source_page and source_detail query params are often used for amplitude events
 */
export var getSourceEventParams = function getSourceEventParams() {
  var queryParams = parseQueryStrings(window.location.search);
  return {
    Source: typeof (queryParams === null || queryParams === void 0 ? void 0 : queryParams.source_page) === 'string' ? decodeURIComponent(queryParams.source_page) : 'Directo',
    'Source Detail': typeof (queryParams === null || queryParams === void 0 ? void 0 : queryParams.source_detail) === 'string' ? decodeURIComponent(queryParams.source_detail) : undefined
  };
};

/**
 * The source_page and source_detail query params are often used for amplitude events
 */
export var createUrlWithSourceQueryParams = _createUrlWithSourceQueryParams;
export var showLogs = function showLogs(logEventConfig) {
  if (customLocalStorage.getItem('amplitude_logger_enabled')) {
    var startGroup = eval('console.group.bind(console)');
    var endGroup = eval('console.groupEnd.bind(console)');
    var info = eval('console.info.bind(console)');
    startGroup('------ Sending amplitude log event ------');
    info('event:', logEventConfig.event);
    info('data:', logEventConfig.data);
    endGroup();
  }
};
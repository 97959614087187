export var DIALOG_SHEET_COL_PROPS = {
  s: {
    xs: 12,
    sm: 8,
    md: 8,
    lg: 6,
    offset: {
      sm: 2,
      lg: 3
    }
  },
  m: {
    xs: 12,
    sm: 10,
    md: 8,
    lg: 8,
    offset: {
      sm: 1,
      md: 2
    }
  },
  l: {
    xs: 12,
    sm: 10,
    md: 10,
    lg: 10,
    offset: {
      sm: 1
    }
  },
  full: {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12
  }
};
export var DIALOG_CONTENT_COL_PROPS = {
  s: {
    xs: DIALOG_SHEET_COL_PROPS.s.xs - 2,
    sm: DIALOG_SHEET_COL_PROPS.s.sm - 2,
    md: DIALOG_SHEET_COL_PROPS.s.sm - 2,
    lg: DIALOG_SHEET_COL_PROPS.s.lg - 2,
    offset: 1
  },
  m: {
    xs: DIALOG_SHEET_COL_PROPS.m.xs,
    sm: DIALOG_SHEET_COL_PROPS.m.sm - 2,
    md: DIALOG_SHEET_COL_PROPS.m.md - 2,
    lg: DIALOG_SHEET_COL_PROPS.m.md - 2,
    offset: {
      sm: 1
    }
  },
  'm-form': {
    xs: DIALOG_SHEET_COL_PROPS.m.xs,
    sm: DIALOG_SHEET_COL_PROPS.m.sm - 4,
    md: DIALOG_SHEET_COL_PROPS.m.md - 4,
    lg: DIALOG_SHEET_COL_PROPS.m.md - 4,
    offset: {
      sm: 2
    }
  },
  full: {
    xs: DIALOG_SHEET_COL_PROPS.full.xs,
    sm: DIALOG_SHEET_COL_PROPS.full.xs - 4,
    md: DIALOG_SHEET_COL_PROPS.full.xs - 6,
    lg: DIALOG_SHEET_COL_PROPS.full.xs - 6,
    offset: {
      sm: 2,
      md: 3
    }
  }
};
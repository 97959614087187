/**
 * DO NOT PUT SENSITIVE VALUES HERE.
 * This file is used in the frontend client and should only contain values that are needed in the frontend.
 * Please put sensitive values in the .env file and in the .env.local file for development.
 */
export var envVars = {
  NODE_ENV: process.env.NODE_ENV,
  /**
   * The environment where the app is running.
   *
   * It will be `qa` when running in qa.creh.co and `production` when running in crehana.com
   */
  APP_ENV: process.env.APP_ENV,
  /** Useful to check if the app is running in production domain (crehana.com) */
  APP_ENV_IS_PRODUCTION: process.env.APP_ENV === 'production',
  APP_ENV_IS_QA: process.env.APP_ENV === 'qa',
  NODE_ENV_IS_PRODUCTION: process.env.NODE_ENV === 'production',
  NODE_ENV_IS_DEVELOPMENT: process.env.NODE_ENV === 'development',
  /** The name of the microfrontend that is running */
  CREHANA_MICROFRONTEND_NAME: process.env.CREHANA_MICROFRONTEND_NAME,
  /** The commit SHA */
  CREH_PROJECT_COMMIT_SHORT_SHA: process.env.CREH_PROJECT_COMMIT_SHORT_SHA,
  // sentry
  CREH_SENTRY_ENABLED: process.env.CREH_SENTRY_ENABLED,
  SENTRY_DSN: process.env.SENTRY_DSN,
  // amplitude
  AMPLITUDE_API_KEY: process.env.AMPLITUDE_API_KEY,
  AMPLITUDE_EXPERIMENTS_KEY: process.env.AMPLITUDE_EXPERIMENTS_KEY,
  // version
  APP_VERSION: process.env.APP_VERSION
};
import _toArray from "@babel/runtime/helpers/toArray";
/**
 * Returns an object based on the keys and values included in a query string.
 *
 * @param {string} qs - The query string that needs to be parsed
 *
 * @example
 * const qs = '?x=abc&y=def';
 * const parsedQs = parseQueryStrings(qs);
 *
 * console.log(parsedQs); // { x: 'abc', y: 'def' }
 */

export var parseQueryStrings = function parseQueryStrings(qs) {
  if (!qs) {
    return;
  }
  var queryStrings = qs.replace('?', '').split('&');
  var parsedObject = {};
  queryStrings.forEach(function (q) {
    var _q$split = q.split('='),
      _q$split2 = _toArray(_q$split),
      key = _q$split2[0],
      rest = _q$split2.slice(1);
    var value = rest.length > 1 ? rest.join('=') : rest[0];

    /**
     * If we don't validate the keyAndValue[0] (key), we will get a { '': true } when qs is equal to "?&"
     */
    if (key) {
      parsedObject[key] = value || true;
    }
  });
  return parsedObject;
};
export default parseQueryStrings;
import { createRecursiveFunctionForAnalytics } from "@crehana/utils";

// Called when a product is added/removed to a shopping cart.
export var googleAnalyticsShoppingCart = function googleAnalyticsShoppingCart(courseId, bundleId, action) {
  createRecursiveFunctionForAnalytics(function () {
    var product;
    if (courseId) {
      product = {
        originalId: courseId,
        type: 'COURSE'
      };
    } else {
      product = {
        originalId: bundleId,
        type: 'CERTIFICATION'
      };
    }
    var items = [{
      id: "".concat(product.type, "_").concat(product.originalId),
      quantity: 1
    }];
    if (action === 'add') {
      if (window.gtag) {
        window.gtag('event', 'add_to_cart', {
          send_to: 'UA-60829200-1',
          items: items
        });
      }
    } else if (action === 'remove') {
      if (window.gtag) {
        window.gtag('event', 'remove_from_cart', {
          send_to: 'UA-60829200-1',
          items: items
        });
      }
    }
  })();
};
export var checkIfItsAllowedPage = function checkIfItsAllowedPage(url) {
  // These are a list of words that are present on the allowed urls
  var ALLOWED_PAGES = [
  // HomeUser
  '/home',
  // Recursos Landing or Detail
  '/recursos/',
  // Curso gratis del dia
  '/curso-gratis-del-dia',
  // Blog
  '/blog/'
  //
  ];
  if (ALLOWED_PAGES.some(function (v) {
    return url.includes(v);
  })) {
    return true;
  }
};
import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { ApolloLink } from '@apollo/client';
var currencyLink = function currencyLink(countryCode) {
  return new ApolloLink(function (operation, forward) {
    var hs = {};
    if (countryCode && countryCode !== '*') {
      hs['creh-country'] = countryCode;
    }
    operation.setContext(function (_ref) {
      var _ref$headers = _ref.headers,
        headers = _ref$headers === void 0 ? {} : _ref$headers;
      return {
        headers: _objectSpread(_objectSpread({}, headers), hs)
      };
    });
    return forward(operation);
  });
};
export default currencyLink;
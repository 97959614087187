var getPaddingClass = function getPaddingClass(gutter, className, nowrap, bp) {
  var value = gutter / 2;

  // Apply full padding if the element doesn't have any custom paddings and nowrap is false
  if (!/p(x|l|r|y|t|b)?-/.test(className || '') && !nowrap) {
    return "".concat(bp ? "".concat(bp, ":") : '', "p-").concat(value);
  }
  var hasLeftPadding = className === null || className === void 0 ? void 0 : className.includes('pl-');
  var hasRightPadding = className === null || className === void 0 ? void 0 : className.includes('pr-');
  var hasHorizontalPadding = (className === null || className === void 0 ? void 0 : className.includes('px-')) || hasLeftPadding && hasRightPadding;
  var hasTopPadding = className === null || className === void 0 ? void 0 : className.includes('pt-');
  var hasBottomPadding = className === null || className === void 0 ? void 0 : className.includes('pb-');
  var hasVerticalPadding = (className === null || className === void 0 ? void 0 : className.includes('py-')) || hasTopPadding && hasBottomPadding;
  var hasFullPadding = (className === null || className === void 0 ? void 0 : className.includes('p-')) || hasHorizontalPadding && hasVerticalPadding;

  // Ignore gutter classes if custom padding is provided for every side
  if (hasFullPadding) {
    return '';
  }

  // Apply only horizontal padding if it has custom vertical paddings or nowrap is true
  if (nowrap || hasVerticalPadding) {
    return "".concat(bp ? "".concat(bp, ":") : '', "px-").concat(value);
  }

  // Apply only vertical padding if it has custom horizontal paddings
  if (hasHorizontalPadding) {
    return "".concat(bp ? "".concat(bp, ":") : '', "py-").concat(value);
  }

  // Apply gutter paddings to each side that doesn't have a custom padding
  var paddingClasses = !hasTopPadding ? "".concat(bp ? "".concat(bp, ":") : '', "pt-").concat(value, " ") : '';
  if (!hasRightPadding) {
    paddingClasses = "".concat(paddingClasses).concat(bp ? "".concat(bp, ":") : '', "pr-").concat(value, " ");
  }
  if (!hasBottomPadding) {
    paddingClasses = "".concat(paddingClasses).concat(bp ? "".concat(bp, ":") : '', "pb-").concat(value, " ");
  }
  if (!hasLeftPadding) {
    paddingClasses = "".concat(paddingClasses).concat(bp ? "".concat(bp, ":") : '', "pl-").concat(value, " ");
  }
  return paddingClasses.replace(/ $/, '');
};
export default getPaddingClass;
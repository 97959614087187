/**
 * Validate if a color has hexadecimal format
 * @param color should be a hex color;
 */
export var isHexColor = function isHexColor(color) {
  var hex = (color !== null && color !== void 0 && color.startsWith('#') ? color.substring(1) : color) || '';
  return hex.length === 6 && !isNaN(Number("0x".concat(hex)));
};

/**
 * Create an 8 digit hex (color with transparency)
 * ref: https://css-tricks.com/converting-color-spaces-in-javascript/#rgba-to-hex-rrggbbaa
 *
 * @param color Should be a hex color
 * @param alpha Number between 0 and 1
 */
export var createHexTransparencyColor = function createHexTransparencyColor(color, alpha) {
  if (isHexColor(color)) {
    // Validate values out of the 0-1 range
    var validatedAlpha = alpha;
    if (validatedAlpha > 1) {
      validatedAlpha = 1;
    } else if (validatedAlpha < 0) {
      validatedAlpha = 0;
    }

    // Convert it to 2 digit hex if the alpha value is lower than 1
    var a = validatedAlpha < 1 ? "0".concat(Math.round(validatedAlpha * 255).toString(16)).slice(-2) : undefined;
    return a ? "".concat(color).concat(a) : color;
  }
  console.warn('Cannot convert color with transparency, using current color:', color);
  return color;
};

/**
 * Create an 8 digit hex (color with transparency)
 * @param color Should be a hex color
 * @param transparencyPercent Number between 0 and 100.
 */
export var getHexTransparencyColor = function getHexTransparencyColor(color, transparencyPercent) {
  return createHexTransparencyColor(color, transparencyPercent / 100);
};
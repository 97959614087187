var getMarginClass = function getMarginClass(gutter, className, nowrap, bp) {
  var value = gutter / 2;

  // Apply full margin if the element doesn't have any custom margins and nowrap is false
  if (!/m(x|l|r|y|t|b)?-/.test(className || '') && !nowrap) {
    return "".concat(bp ? "".concat(bp, ":") : '', "-m-").concat(value);
  }
  var hasLeftMargin = className === null || className === void 0 ? void 0 : className.includes('ml-');
  var hasRightMargin = className === null || className === void 0 ? void 0 : className.includes('mr-');
  var hasHorizontalMargin = (className === null || className === void 0 ? void 0 : className.includes('mx-')) || hasLeftMargin && hasRightMargin;
  var hasTopMargin = className === null || className === void 0 ? void 0 : className.includes('mt-');
  var hasBottomMargin = className === null || className === void 0 ? void 0 : className.includes('mb-');
  var hasVerticalMargin = (className === null || className === void 0 ? void 0 : className.includes('my-')) || hasTopMargin && hasBottomMargin;
  var hasFullMargin = (className === null || className === void 0 ? void 0 : className.includes('m-')) || hasHorizontalMargin && hasVerticalMargin;

  // Ignore gutter classes if custom margin is provided for every side
  if (hasFullMargin) {
    return '';
  }

  // Apply only horizontal margin if it has custom vertical margins or nowrap is true
  if (nowrap || hasVerticalMargin) {
    return "".concat(bp ? "".concat(bp, ":") : '', "-mx-").concat(value);
  }

  // Apply only vertical margin if it has custom horizontal margins
  if (hasHorizontalMargin) {
    return "".concat(bp ? "".concat(bp, ":") : '', "-my-").concat(value);
  }

  // Apply gutter margins to each side that doesn't have a custom margin
  var marginClasses = !hasTopMargin ? "".concat(bp ? "".concat(bp, ":") : '', "-mt-").concat(value, " ") : '';
  if (!hasRightMargin) {
    marginClasses = "".concat(marginClasses).concat(bp ? "".concat(bp, ":") : '', "-mr-").concat(value, " ");
  }
  if (!hasBottomMargin) {
    marginClasses = "".concat(marginClasses).concat(bp ? "".concat(bp, ":") : '', "-mb-").concat(value, " ");
  }
  if (!hasLeftMargin) {
    marginClasses = "".concat(marginClasses).concat(bp ? "".concat(bp, ":") : '', "-ml-").concat(value, " ");
  }
  return marginClasses.replace(/ $/, '');
};
export default getMarginClass;
import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { createHttpLink, split } from '@apollo/client';
import { GRAPH_ENDPOINT, GRAPH_ENDPOINT_AUTH, GRAPH_ENDPOINT_B2B, GRAPH_ENDPOINT_CENTRALIZED, GRAPH_ENDPOINT_PAYMENTS, GRAPH_ENDPOINT_SEARCH, GRAPH_ENDPOINT_TALENT_FEEDBACK, GRAPH_ENDPOINT_V3, GRAPH_ENDPOINT_V4, GRAPH_ENDPOINT_V4_AUTHENTICATION, GRAPH_ENDPOINT_V4_B2B, GRAPH_ENDPOINT_V4_STAFF, GRAPH_ENDPOINT_V5, GRAPH_ENDPOINT_V5_ANALYTICS } from "../constants";
function createIsomorphLink() {
  var common = {
    credentials: 'include'
  };
  var mainLink = createHttpLink(_objectSpread({
    uri: GRAPH_ENDPOINT
  }, common));
  var auth = split(function (operation) {
    return operation.getContext().clientName === 'auth';
  }, createHttpLink(_objectSpread({
    uri: "".concat(GRAPH_ENDPOINT_AUTH)
  }, common)), mainLink);
  var b2bLink = split(function (operation) {
    return operation.getContext().clientName === 'b2b';
  }, createHttpLink(_objectSpread({
    uri: "".concat(GRAPH_ENDPOINT_B2B)
  }, common)), auth);
  var v4Link = split(function (operation) {
    return operation.getContext().clientName === 'v4';
  }, createHttpLink(_objectSpread({
    uri: GRAPH_ENDPOINT_V4
  }, common)), b2bLink);
  var v4AuthenticationLink = split(function (operation) {
    return operation.getContext().clientName === 'v4.authentication';
  }, createHttpLink(_objectSpread({
    uri: GRAPH_ENDPOINT_V4_AUTHENTICATION
  }, common)), v4Link);
  var v4b2bLink = split(function (operation) {
    return operation.getContext().clientName === 'v4.b2b';
  }, createHttpLink(_objectSpread({
    uri: GRAPH_ENDPOINT_V4_B2B
  }, common)), v4AuthenticationLink);
  var v3Link = split(function (operation) {
    return operation.getContext().clientName === 'v3';
  }, createHttpLink(_objectSpread({
    uri: GRAPH_ENDPOINT_V3
  }, common)), v4b2bLink);
  var v4staffLink = split(function (operation) {
    return operation.getContext().clientName === 'v4.staff';
  }, createHttpLink(_objectSpread({
    uri: GRAPH_ENDPOINT_V4_STAFF
  }, common)), v3Link);
  var paymentsLink = split(function (operation) {
    return operation.getContext().clientName === 'payments';
  }, createHttpLink(_objectSpread({
    uri: GRAPH_ENDPOINT_PAYMENTS
  }, common)), v4staffLink);
  var v5Link = split(function (operation) {
    return operation.getContext().clientName === 'v5';
  }, createHttpLink(_objectSpread({
    uri: GRAPH_ENDPOINT_V5
  }, common)), paymentsLink);
  var v5AnalyticsLink = split(function (operation) {
    return operation.getContext().clientName === 'v5.analytics';
  }, createHttpLink(_objectSpread({
    uri: GRAPH_ENDPOINT_V5_ANALYTICS
  }, common)), v5Link);
  var talentLink = split(function (operation) {
    return operation.getContext().clientName === 'talent.feedback';
  }, createHttpLink(_objectSpread({
    uri: GRAPH_ENDPOINT_TALENT_FEEDBACK
  }, common)), v5AnalyticsLink);
  var schemaLinks = split(function (operation) {
    return operation.getContext().clientName === 'search';
  }, createHttpLink(_objectSpread({
    uri: GRAPH_ENDPOINT_SEARCH
  }, common)), talentLink);
  var centralizedLink = split(function (operation) {
    return operation.getContext().clientName === 'centralized';
  }, createHttpLink(_objectSpread({
    uri: GRAPH_ENDPOINT_CENTRALIZED
  }, common)), schemaLinks);
  var mockV5Link = split(function (operation) {
    return operation.getContext().clientName === 'mock.v5';
  }, createHttpLink(_objectSpread({
    uri: "".concat(process.env.API_URL_MOCK, "/api/v5/graph/")
  }, common)), centralizedLink);
  var mockCentralizedLink = split(function (operation) {
    return operation.getContext().clientName === 'mock.centralized';
  }, createHttpLink(_objectSpread({
    uri: "".concat(process.env.API_URL_MOCK, "/api/graph/centralized/")
  }, common)), mockV5Link);

  // mock for v2
  var mockMainLink = split(function (operation) {
    return operation.getContext().clientName === 'mock.v2';
  }, createHttpLink(_objectSpread({
    uri: "".concat(process.env.API_URL_MOCK, "/api/v2/graph/")
  }, common)), mockCentralizedLink);
  return mockMainLink;
}
export default createIsomorphLink;
import { LANGUAGE_COOKIE, LANGUAGE_HEADER_KEY } from '@crehana/constants';
import { LANGUAGES } from '@crehana/ts-types';
import parseCookies from "./parseCookies";
export function getG11nDataFromHeaders(headers) {
  var language = null;
  var country = null;
  var countryFromHeader = headers['creh-country'];
  var cookies = headers.cookie ? parseCookies(headers.cookie) : {};
  /**
   * Always use the header as first option
   */

  if (countryFromHeader) {
    country = countryFromHeader !== '*' ? countryFromHeader.toLowerCase() : '';
  } else if (headers.cookie) {
    /**
     * fallback to cookies if the creh-country header has no value
     */
    country = cookies.__creh_country_code;
    country = country && country !== '*' ? country.toLowerCase() : '';
  }
  language = cookies[LANGUAGE_COOKIE] || headers[LANGUAGE_HEADER_KEY] || (country === 'br' ? 'pt' : 'es');
  return {
    countryCode: country || '',
    languageCode: language || LANGUAGES.ES
  };
}
export default getG11nDataFromHeaders;
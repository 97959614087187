import _objectSpread from "@babel/runtime/helpers/objectSpread2";
/**
 * A hack to add the 'x-crehana-microfrontend-name' header in each request to `/_next/data`.
 * With this header, our webprox can identify what microfrontend is doing the request and with that
 * it can rewrite that request to the correct microfrontend domain.
 */
export var addExtraOptionsToFechApi = function addExtraOptionsToFechApi() {
  if (typeof window === 'undefined') {
    return;
  }
  var originalFech = window.fetch;
  window.fetch = function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    try {
      var _args$0$search;
      if (typeof args[0] === 'string' && args[0].includes('/_next/data/') || args[0] instanceof URL && (_args$0$search = args[0].search) !== null && _args$0$search !== void 0 && _args$0$search.includes('_rsc=')) {
        if (typeof args[1] === 'object') {
          args[1] = _objectSpread(_objectSpread({}, args[1]), {}, {
            headers: _objectSpread(_objectSpread({}, args[1].headers || {}), {}, {
              'x-crehana-microfrontend-name': process.env.CREHANA_MICROFRONTEND_NAME || 'crehana-generic'
            })
          });
        }
      }
    } catch (e) {
      console.error('error to add extra headers to /_next/data', e);
    }
    return originalFech.apply(void 0, args);
  };
};